<template>
  <div class="dimesion-row-container">
    <div class="dimension-btn"><a-icon class="dimension-icon" type="radar-chart" />维度</div>
    <div class="dimension_list sort-checkbox-group">
      <template v-for="(item, index) in allDimesion">
        <a-checkbox
          class="checkbox-item"
          @click="changeDimension(item)"
          v-if="index <= maxShowNum - 1 || (index > maxShowNum - 1 && visible)"
          :key="item.prop"
          :checked="query.groupByList.includes(item.key)"
        >{{ item.label || item.name }}</a-checkbox
        >
      </template>
    </div>
    <div class="expend" v-if="allDimesion.length > maxShowNum">
      <span
        type="link"
        class="button"
        @click="visible = !visible"
      >{{ visible ? '收起' : '展开' }}<a-icon
        :type="visible ? 'down' : 'up'"
      /></span>
    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs'
export default {
  name: 'DimesionRow',
  data () {
    return {
      visible: false
    }
  },
  props: {
    allDimesion: {
      default: () => [],
      type: Array
    },
    query: {
      default: () => {},
      type: Object
    },
    maxShowNum: {
      default: 7,
      type: Number
    }
  },
  computed: {},
  mounted () {
    this.initSortable()
  },
  methods: {
    changeDimension (item) {
      this.$emit('changeDimension', item)
    },
    // 维度拖拽排序
    initSortable () {
      const el = document.querySelector('.sort-checkbox-group')
      /* eslint-disable no-new */
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          this.handelDimesionSort(evt.newIndex, evt.oldIndex)
        }
      })
    },
    // 拖拽回调处理
    handelDimesionSort (newIndex, oldIndex) {
      const oldItem = this.allDimesion.splice(oldIndex, 1)
      // this.allDimesion.splice(newIndex, 0, oldItem[0])
      this.$emit('changeDimesionSort', newIndex, oldItem[0])
      this.$nextTick(() => {
        this.initSortable()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.checkbox-item {
  overflow: auto;
}
.dimesion-row-container {
  width: 100%;
  position: relative;
  display: flex;
  // align-items: center;
  .dimension-btn {
    // padding-top: 7px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 4px;
    width: 60px;
    .dimension-icon {
      color: @assisColor;
      font-size: 15px;
      margin-right: 4px;
    }
  }
  .expend {
    width: 100px;
    .button{
      color: @primary-color;
      cursor: pointer;
    }
  }
  .dimension_list {
    // padding-top: 8px;
    flex: 1;
    // display: grid;
    // grid-template-columns: repeat(8, 1fr);
    display: flex;
    flex-wrap: wrap;
    gap: 10px 5px;
  }
}
.dimension_list {
  display: flex;
  height: 100%;
  position: relative;
  padding-left: 20px;
  &::before {
    content: '';
    position: absolute;
    // top: 2px;
    left: 0;
    width: 2px;
    height: 20px;
    background-color: #15223216;
    // transform: translateY(30%);
  }
}
::v-deep {
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}
</style>
